export const numFormat = (number: number) =>
  new Intl.NumberFormat('ru-RU').format(number);

export const currencyRuLocaleWithoutFraction = (
  number: number,
  options = { maxFraction: 0, minFraction: 0 }
) =>
  new Intl.NumberFormat('ru-RU', {
    currency: 'RUB',
    style: 'currency',
    maximumFractionDigits: options.maxFraction,
    minimumFractionDigits: options.minFraction,
  }).format(number);

export const currencyFormat = (number: number) => {
  if (number > 1_000_000) {
    const truncNumber = Math.floor(number / 1_000_000);
    return `${numFormat(truncNumber)} млн`;
  }
  return numFormat(number);
};
