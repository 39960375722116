import { memo, useCallback, useState } from 'react';
import type { FC, SyntheticEvent } from 'react';
import { StyledCheckbox } from '@shared/styles';
import { t } from 'i18next';
import { i18nDefaultValues } from '../../i18n';
import { PromoCode } from './promo-code';
import { ProductPrice } from '@pulse-smart-components-kit/common';

interface Props {
  price: ProductPrice;
  onChange: (promoCode: Partial<ProductPrice>) => void;
  onSubmit: (promoCode: string) => void;
  onClear: () => void;
}

export const PromoCodeOptional: FC<Props> = memo(
  ({ price, onChange, onSubmit, onClear }) => {
    const [hasPromoCode, setHasPromoCode] = useState(
      !!price.promoCode || !!price.applyedPromoCode
    );

    const handleHasPromoCode = useCallback(
      ({ currentTarget: { checked } }: SyntheticEvent<HTMLInputElement>) => {
        setHasPromoCode(checked);
        if (!checked) {
          onChange({ promoCode: '' });
          onClear();
        }
      },
      [setHasPromoCode, onChange, onClear]
    );

    const handleLocalPromoCode = useCallback(
      (value: string) => {
        onChange({ promoCode: value, isApplyedPromo: false });
      },
      [onClear]
    );

    return (
      <>
        <div data-test="Promo-code-checkbox">
          <StyledCheckbox
            checked={hasPromoCode}
            onChange={handleHasPromoCode}
            label={t('SMART:Submit.labels.havePromoCode', {
              defaultValue: i18nDefaultValues.Submit.labels.havePromoCode,
            })}
          />
        </div>
        {hasPromoCode && (
          <PromoCode
            onSubmit={onSubmit}
            onChange={handleLocalPromoCode}
            price={price}
          />
        )}
      </>
    );
  }
);

PromoCodeOptional.displayName = 'PromoCodeOptional';
