import { Suspense, forwardRef, type Ref, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { useStores } from '@shared/hooks';
import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import { InsuredPersons } from '@entities/import-smart-components/insured-persons-component';
import {
  InsuredPersonsOptions,
  InsuredPersonsValues,
  InsuredPersonsAnalyticEvent,
} from '@pulse-smart-components-kit/insured-persons';
import { getInsuredPersonsSchema } from './schemas';
import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { AnalyticEventsMap } from '@shared/types';

const analyticEventsMap: AnalyticEventsMap<InsuredPersonsAnalyticEvent> = {
  [InsuredPersonsAnalyticEvent.ON_DATA_ENTERED]: {
    name: analyticEvents.sportsmanDataEntered,
  },
  [InsuredPersonsAnalyticEvent.ON_PERSON_ADD]: {
    name: analyticEvents.sportsmanAdded,
  },
  [InsuredPersonsAnalyticEvent.ON_PERSON_REMOVE]: {
    name: analyticEvents.sportsmanDeleted,
  },
};

export const InsuredPersonsAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<InsuredPersonsValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          initProductStore: { initState },
          authStore: { disableForm, profile },
          productStore: {
            ageRange,
            formState: { WhoIssuesPolicy, NumberOfInsuredPersons },
          },
          applicationStore: { updateFormValue },
        },
      } = useStores();

      const formSchema = getInsuredPersonsSchema();

      const handleDeleteMyself = useCallback(() => {
        updateFormValue('WhoIssuesPolicy', {
          ...WhoIssuesPolicy,
          forMe: false,
        });
      }, [WhoIssuesPolicy]);

      const choosedPersonNumber =
        NumberOfInsuredPersons?.numberOfInsuredPersons ?? 1;

      const options: InsuredPersonsOptions = useMemo(
        () => ({
          isMe: WhoIssuesPolicy?.forMe,
          choosedPersonNumber,
          maxPersonNumber: initState?.maxObjectsNumber ?? 1,
          ageRange,
          onDeleteMyself: handleDeleteMyself,
          lockedFields: profile?.lockedFields,
        }),
        [
          WhoIssuesPolicy?.forMe,
          choosedPersonNumber,
          ageRange,
          handleDeleteMyself,
          initState?.maxObjectsNumber,
          profile?.lockedFields,
        ]
      );

      const onHandleChange = useCallback(
        (data: InsuredPersonsValues) => {
          updateFormValue('NumberOfInsuredPersons', {
            isValid: true,
            numberOfInsuredPersons: data.insuredPersons.length,
          });
          onChange(data);
        },
        [updateFormValue, onChange]
      );

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<InsuredPersonsAnalyticEvent>(
          analyticEventsMap
        );

      if (!WhoIssuesPolicy?.hasAuthorized) return null;

      return (
        <Suspense>
          <InsuredPersons
            ref={forwardRef}
            value={!value?.insuredPersons ? { insuredPersons: [] } : value}
            onChange={onHandleChange}
            isSubmitting={isSubmitting}
            options={options}
            disabled={disableForm}
            fieldState={fieldState}
            formSchema={formSchema}
            onAnalyticEventSend={handleAnalyticEventSend}
          />
        </Suspense>
      );
    }
  )
);

InsuredPersonsAdapter.displayName = 'InsuredPersonsAdapter';
