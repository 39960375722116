import { FooterSport } from '@entities/import-smart-components/footer-sport';
import { Suspense, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@shared/hooks';
import { useUpdateFooter } from './use-update-footer';
import { FallbackSkeleton } from '@shared/components';
import type { FC } from 'react';
import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import type { AnalyticEventsMap } from '@shared/types';
import { FooterAnalyticEvent } from '@pulse-smart-components-kit/footer-sport';
import { getInsuranceDuration } from '@shared/utils';

const analyticEventsMap: AnalyticEventsMap<FooterAnalyticEvent> = {
  [FooterAnalyticEvent.ON_OPEN]: {
    name: analyticEvents.selectedSummary,
  },
  [FooterAnalyticEvent.ON_OPEN_ITEMS_MODAL]: {
    name: analyticEvents.selectedSummary,
  },
};

export const FooterAdapter: FC = observer(() => {
  const {
    MainStore: {
      applicationStore: {
        activeStep,
        flowConfig: { maxSteps },
      },
      initProductStore: {
        insuranceLimitStart: { maxDuration },
      },
      productStore: { hasPricesCalcErr, formState },
    },
  } = useStores();

  const value = useUpdateFooter();

  const handleAnalyticEventSend =
    useHandleSmartComponentsAnalyticEvents<FooterAnalyticEvent>(
      analyticEventsMap
    );

  const isShowStartsFrom = useMemo(
    () =>
      getInsuranceDuration({
        insurancePeriod: formState?.InsurancePeriod,
        maxDuration,
      }).isValid,
    [formState?.InsurancePeriod, maxDuration]
  );

  return (
    <Suspense fallback={<FallbackSkeleton height={60} />}>
      <FooterSport
        value={value}
        activeStep={activeStep}
        hasPricesCalcErr={hasPricesCalcErr}
        maxSteps={maxSteps}
        onAnalyticEventSend={handleAnalyticEventSend}
        isShowStartsFrom={isShowStartsFrom}
      />
    </Suspense>
  );
});

FooterAdapter.displayName = 'FooterAdapter';
