import debounce from 'lodash.debounce';
import { Suspense, forwardRef, type Ref, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { useStores } from '@shared/hooks';
import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import {
  CoverageSumValues,
  CoverageSumAnalyticEvent,
} from '@pulse-smart-components-kit/coverage-sum';
import { CoverageSum } from '@entities/import-smart-components/coverage-sum-component';
import { getCoverageSumSchema } from './schemas';
import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { AnalyticEventsMap } from '@shared/types';

const analyticEventsMap: AnalyticEventsMap<CoverageSumAnalyticEvent> = {
  [CoverageSumAnalyticEvent.ON_COVERAGE_SUM_CHANGE]: {
    name: analyticEvents.sumChanged,
  },
  [CoverageSumAnalyticEvent.ON_OPEN_MODAL]: {
    name: analyticEvents.infoSum,
  },
};

export const CoverageSumAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<CoverageSumValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          initProductStore: {
            initState: { sublimits, risks },
          },
          authStore: { disableForm },
        },
      } = useStores();

      const handleChangeCoverageSumDebounced = debounce(onChange, 200);

      useEffect(() => handleChangeCoverageSumDebounced.cancel, []);

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<CoverageSumAnalyticEvent>(
          analyticEventsMap
        );

      const handleAnalyticEventSendDebounced = debounce(
        handleAnalyticEventSend,
        200
      );

      const min = useMemo(
        () =>
          sublimits?.totalMinLimit ? parseFloat(sublimits.totalMinLimit) : 0,
        [sublimits?.totalMinLimit]
      );

      const max = useMemo(
        () =>
          sublimits?.totalMaxLimit ? parseFloat(sublimits.totalMaxLimit) : 0,
        [sublimits?.totalMaxLimit]
      );

      const formSchema = getCoverageSumSchema(min, max);

      const activeRisks = useMemo(
        () =>
          risks
            ?.filter((risk) => risk.active)
            .map(({ name, description, code }) => ({
              name,
              description,
              code,
            })),
        [risks]
      );

      const options = useMemo(
        () => ({
          min,
          max,
          activeRisks,
          step: sublimits?.totalStep ? parseFloat(sublimits.totalStep) : 0,
        }),
        [sublimits?.totalStep, min, max, activeRisks]
      );

      const coverageSumValue = useMemo(() => {
        if (
          !!value &&
          !value?.coverageSum &&
          !!sublimits?.totalDefaultInsuranceSum
        ) {
          return {
            ...value,
            coverageSum: parseFloat(sublimits.totalDefaultInsuranceSum),
          };
        }

        return value;
      }, [value, sublimits?.totalDefaultInsuranceSum]);

      if (!coverageSumValue?.coverageSum) return null;

      return (
        <Suspense>
          <CoverageSum
            ref={forwardRef}
            value={coverageSumValue}
            onChange={handleChangeCoverageSumDebounced}
            isSubmitting={isSubmitting}
            options={options}
            disabled={disableForm}
            fieldState={fieldState}
            formSchema={formSchema}
            onAnalyticEventSend={handleAnalyticEventSendDebounced}
          />
        </Suspense>
      );
    }
  )
);

CoverageSumAdapter.displayName = 'CoverageSumAdapter';
