import type { AgeRatio } from '@shared/types';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { getAgeRange } from './utils';
import { Main } from '@app/store';
import { FieldValues } from 'react-hook-form';
import { NumberOfInsuredPersonsValues } from '@pulse-smart-components-kit/number-of-insured-persons';
import { InsuredPersonsValues } from '@pulse-smart-components-kit/insured-persons';
import { CoverageSumValues } from '@pulse-smart-components-kit/coverage-sum';
import { InsurancePeriodValues } from '@pulse-smart-components-kit/insurance-period';
import { SmartComponentId } from '@shared/constants';
import {
  CheckPolicyValues,
  DocumentType,
} from '@pulse-smart-components-kit/check-policy';
import { ProductPrice } from '@pulse-smart-components-kit/common';

const ageRatios: AgeRatio[] = [
  {
    ageMin: 0,
    ageMax: 200,
    ageCategory: 'Взрослый',
  },
];

export interface FormStateType {
  WhoIssuesPolicy?: FieldValues;
  [SmartComponentId.CHECK_POLICY]?: CheckPolicyValues;
  [SmartComponentId.NUMBER_OF_INSURED_PERSONS]?: NumberOfInsuredPersonsValues;
  [SmartComponentId.INSURED_PERSONS]?: InsuredPersonsValues;
  [SmartComponentId.COVERAGE_SUM]?: CoverageSumValues;
  [SmartComponentId.INSURANCE_PERIOD]?: InsurancePeriodValues;
}

interface OrderDataType {
  billId: string;
  clientDocument?: DocumentType[];
  contractId: string;
  contractNumber: string;
  orderNumber: string;
  premiumAndDelta: string;
  subscriptionId: string;
  subscriptionNumber: string;
  subscriptionType: string;
}

export class ProductStore {
  private mainStore: Main;
  pulseClient?: boolean; // Связь с чекбоксом “Я клиент СК «Пульс»” на авторизации
  ageRatios: AgeRatio[] = ageRatios; // Для валидации, когда мы выбираем возраст путешественника
  formState: FormStateType = {}; // Форма со смарт компонентами и их базовыми свойствами
  formString: string = ''; // Форма смарт компонентов в виде строки, используется в bff
  price: ProductPrice = { premiumAndDelta: '0', promoCode: '' }; // Цена в футере
  orderData?: OrderDataType = undefined; // Данные полученные с post запроса submit-order
  hasPricesCalcErr: boolean = false; // Ошибка в цене, отрисовка в cостояние error, если сумма превышает досигаемой.
  isPolicyBanned: boolean = false; // Признак ошибки о невозможности оформления полиса.

  constructor(mainStore: Main) {
    this.mainStore = mainStore;
    makeAutoObservable(this, {
      pulseClient: observable,
      setPulseClient: action,
      ageRatios: observable,
      formState: observable,
      setFormState: action,
      setOrderData: action,
      formString: observable,
      price: observable,
      setPrice: action,
      setHasPricesCalcErr: action,
      isPolicyBanned: observable,
      clearPromoCode: action,
      setIsPolicyBanned: action,
      clearDocumentsList: action,
    });
  }

  get ageRange() {
    return getAgeRange(this.ageRatios);
  }

  setPrice = (e: Partial<ProductPrice>) => {
    this.price = { ...this.price, ...e };
  };

  clearPromoCode = () => {
    this.price = {
      ...this.price,
      isSuccessfulPromo: undefined,
      premiumAndDeltaPromo: undefined,
    };
  };

  setHasPricesCalcErr = (e: boolean) => {
    this.hasPricesCalcErr = e;
  };

  setOrderData = (e: OrderDataType) => {
    this.orderData = e;
  };

  setPulseClient = (e: boolean) => {
    this.pulseClient = e;
  };

  setFormState = (e: Partial<FormStateType>) => {
    runInAction(() => {
      this.formState = {
        ...e,
      };
      this.formString = JSON.stringify(e);
    });
  };

  setIsPolicyBanned = (e: boolean) => {
    this.isPolicyBanned = e;
    runInAction(() => {
      this.mainStore.applicationStore.setIsHeaderShowing(!e);
      this.mainStore.applicationStore.setIsFooterShowing(!e);
      this.mainStore.applicationStore.setIsSubmitShowing(!e);
      this.mainStore.applicationStore.setIsSmartComponentsShowing(!e);
    });
  };

  clearDocumentsList = () => {
    if (this.orderData) this.orderData.clientDocument = undefined;
  };
}
